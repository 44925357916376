import "react-toastify/dist/ReactToastify.min.css";

import React, { useEffect, useState } from "react";
import { camelCase, isEmpty, isNumber } from "lodash";
import { toast } from "react-toastify";

import { useFormGenerator } from "lib/@forms/hooks";
import { formsService } from "lib/@forms/services";
import { Button } from "lib/@cms/components/primitive";
import { ConfirmationModal } from "lib/@cms/components/shared";
import { useModal } from "lib/@cms/hooks";
import { CustomToastContainer } from "lib/@forms/components/styled";
import Form from "./Form";

export default function FormApplicationTemplate({
  submitButtonText = "SUBMIT APPLICATION",
  securedFormFriendlyName = "",
  buttonStyles: $buttonStyles = undefined /** String<CSS class> */,
  onSubmit: $onSubmit = undefined /** Function<formikData>(values, actions)<CSS class> */,
  data: demoData = {},
  noGap = false,
  noMargin = false,
  noPadding = false,
  noBackground = false,
  centerSubmitBtn = true,
}) {
  const [{ data: formData, options }, setFormData] = useState({});

  const { isModalVisible, showModal, closeModal } = useModal();

  const {
    /** formikKeyNames = {}, */
    formikValidationModels = {},
    formik,
    inputs = [],
  } = useFormGenerator(
    demoData.options ? demoData.options : options,
    demoData.data ? demoData.data : formData,
    { onSubmit, onValidate },
  );

  const hasInputs = !isEmpty(inputs);

  const hasErrors = !formik.isValid || !hasInputs;

  async function onSubmit(/** values, actions */) {
    try {
      await showModal();
    } catch (error) {
      closeModal();
    }
  }

  async function onAcceptConditions() {
    try {
      const { values, setSubmitting } = formik;
      if ($onSubmit !== undefined) {
        await $onSubmit(values, { setSubmitting });

        closeModal();

        return;
      }

      if (hasErrors) {
        closeModal();

        return;
      }

      const formDataFormatted = Object.entries(values).reduce((acc, [key, value]) => {
        const keyName = key.split("_");

        const inputName = keyName[0];

        const inputValueName = keyName[1];

        const item = { ...acc };

        if (inputName) {
          item[inputName] = {
            ...acc[inputName],
          };

          if (!!value && !!inputName && !!inputValueName) {
            item[inputName] = {
              ...item[inputName],
              [inputValueName]: value,
            };
          } else item[inputName] = value;
        }

        return item;
      }, {});

      const { success = false /** data = {}, error = {} */ } = await formsService({
        securedFormFriendlyName,
        type: "POST",
        formData: formDataFormatted,
      });

      closeModal();

      if (!success) {
        toast.error(
          <p className="bl-text-sm tw-text-center">There was an error submitting the form.</p>,
          { toastId: "fetch-error-ups" },
        );

        return;
      }

      toast.success(
        <p className="bl-text-sm tw-text-center">Your form has been successfully submitted.</p>,
        { toastId: "onSubmit" },
      );

      setSubmitting(false);
    } catch (error) {
      closeModal();
    }
  }

  function onValidate(/** values = {} */) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const errors = {};

    const formikValidationModelsMultiArray = Object.entries(formikValidationModels);

    const formikValuesAsMultiArray = Object.entries(formik.values);

    const requiredValuesAreMissing = formikValuesAsMultiArray
      .map(([$itemKey, $itemValue]) => {
        const $model = formikValidationModelsMultiArray.find(([$key]) => $key === $itemKey);

        const $modeRuleIsRequired =
          !!$model && !!$model[1] && !isEmpty($model[1]) && $model[1].isRequired;

        return {
          key: $itemKey,
          value: $itemValue,
          isRequired: $modeRuleIsRequired,
        };
      })
      .filter($item => $item.isRequired);

    requiredValuesAreMissing.forEach(missingValue => {
      const formikMissingValue = formik.values[missingValue.key] || "";

      if (missingValue.isRequired && !formikMissingValue) {
        errors[missingValue.key] = `Field "${missingValue.key}" is mandatory `;
      }
    });

    /** Start Validations */
    formikValidationModelsMultiArray.forEach(([inputName = "", inputValidationRules = {}]) => {
      /** Example:
       * console.log(name, inputValidationRules);
       *  Validate Rules
       *    Example:
       *      if (X === Y) {
       *        errors[inputName] = 'Something happened';
       *        showToast(errors[inputName]);
       *      }
       */

      const isNumb = /number/i.test(inputValidationRules.type);

      const isEmail = /email/i.test(inputValidationRules.type);

      const isCheckbox = /checkbox/i.test(inputValidationRules.type);

      const isDateline = /(date|dateline)/i.test(inputValidationRules.type);

      const isReCaptcha = /reCaptcha/i.test(inputValidationRules.type);

      const formikValue = formik ? formik.values[inputName] : "";

      const formikNumberValue = Number(formikValue);

      const formikValueLength = (formikValue || "").length;

      const maxLengthRule = inputValidationRules.maxLength;

      const minLengthRule = inputValidationRules.minLength;

      if (isEmail && !emailPattern.test(formikValue)) {
        errors[inputName] = `Field is not a valid email address`;
      }

      /** Validations with regular expressions */
      if (
        inputValidationRules.regex &&
        Array.isArray(inputValidationRules.regex) &&
        !isEmpty(inputValidationRules.regex)
      ) {
        /** traversing regular expressions */
        inputValidationRules.regex.forEach(({ labelError: message = "", rule }) => {
          /** new regular expression */
          const expression = new RegExp(rule);

          const isNotMatched = !expression.test(formikValue);

          /** validating that the value of Formik is valid for the new regular expression */
          if (isNotMatched) {
            errors[inputName] = message;
          }
        });
      }

      if (isNumber(formikValueLength)) {
        const isValuesNumberGreaterThan = formikValueLength > maxLengthRule;

        const isValuesNumberLessThan = formikValueLength < minLengthRule;

        if (isNumber(maxLengthRule) && (isValuesNumberGreaterThan || isValuesNumberLessThan)) {
          errors[inputName] = `This field cannot be greater than ${inputValidationRules.maxLength}`;
        }

        if (isNumber(minLengthRule) && formikValueLength < minLengthRule) {
          errors[inputName] = `This field cannot be less than ${inputValidationRules.minLength}`;
        }
      }

      if (isNumb) {
        const isValuesNumberGreaterThan = formikNumberValue > maxLengthRule;

        const isValuesNumberLessThan = formikNumberValue < minLengthRule;

        if (!isNumber(formikNumberValue)) {
          errors[inputName] = `Field is not a number`;
        } else {
          if (isNumber(maxLengthRule) && (isValuesNumberGreaterThan || isValuesNumberLessThan)) {
            errors[
              inputName
            ] = `This field cannot be greater than ${inputValidationRules.maxLength}`;
          }

          if (isNumber(minLengthRule) && formikNumberValue < minLengthRule) {
            errors[inputName] = `This field cannot be less than ${inputValidationRules.minLength}`;
          }
        }
      }

      if (isReCaptcha) {
        /** ReCaptcha Validations */
      }

      if (isCheckbox) {
        /** Checkbox Validations */
      }

      if (isDateline) {
        /** Dateline Validations */
      }

      if (!formikValue && inputValidationRules.isRequired) {
        errors[inputName] = `This field is mandatory`;
      }
    });
    /** End Validations */

    return errors;
  }

  useEffect(() => {
    (async () => {
      if (isEmpty(demoData)) {
        const { success = false, data = {}, error } = await formsService({
          securedFormFriendlyName,
        });

        if (success) setFormData(data.fields);
        else {
          toast.error(`ERROR: ${error.message}`, {
            toastId: "fetch-error",
          });
        }
      }
    })();
  }, []);

  return (
    <div
      className={`tw-grid tw-grid-cols-1 tw-grid-rows-1 ${
        noBackground ? "" : "tw-bg-gray-50"
      } tw-p-${noPadding ? 0 : 2} tw-h-full tw-m-0`}
    >
      <form
        onSubmit={formik.handleSubmit}
        className={`tw-my-0 ${noMargin ? "" : "tw-mx-auto"} tw-p-${
          noPadding ? 0 : 2
        } tw-max-w-full sm:tw-max-w-3xl tw-grid tw-grid-cols-2 divide-y`}
      >
        {inputs.map(inputProps => (
          <Form
            {...inputProps}
            noGap={noGap}
            noMargin={noMargin}
            formik={formik}
            key={camelCase(`${inputProps.key || inputProps.title}-${inputProps.id}`)}
          />
        ))}

        {hasInputs && (
          <div className={`tw-col-span-2 ${centerSubmitBtn ? "tw-text-center" : ""}`}>
            <Button
              styleType={Button.styleType.STANDARD}
              fill={Button.fill.FILLED}
              disabled={!!hasErrors}
              className={$buttonStyles || "bl-text-sm"}
              type="submit"
            >
              {submitButtonText}
            </Button>
          </div>
        )}
      </form>

      <CustomToastContainer draggable limit={2} position="bottom-right" />

      <ConfirmationModal
        message="Thanks for getting in touch!"
        acceptBtnText="Send"
        isModalVisible={isModalVisible}
        showModal={showModal}
        closeModal={closeModal}
        onAccept={onAcceptConditions}
      />
    </div>
  );
}
