import { INPUT, INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

export default function getColor({
  like = INPUT || INPUT_TEXT,
  error = false,
  success = false,
  disable = false,
  withBorder = false,
}) {
  if (disable && withBorder) return "var(--bl-color-grayscale-80)";

  if (disable) return "var(--bl-color-grayscale-50)";

  if (like === INPUT) {
    if (withBorder) {
      if (error) return "var(--bl-color-ui-alert)";
      if (success) return "var(--bl-color-primary)";
    }

    if (error) return "var(--bl-color-ui-alert-faded)";
    if (success) return "var(--bl-color-primary-6)";

    return "var(--bl-color-grayscale-6)";
  }

  if (like === INPUT_TEXT) {
    if (withBorder) {
      if (error) return "var(--bl-color-ui-alert)";
      if (success) return "var(--bl-color-primary)";
    }

    if (error) return "var(--bl-color-ui-alert)";
    if (success) return "var(--bl-color-primary)";

    return "var(--bl-color-grayscale-50)";
  }

  if (withBorder) {
    return "var(--bl-color-grayscale-80)";
  }

  return "var(--bl-color-grayscale-30)";
}
