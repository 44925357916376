import styled from "styled-components";

export default styled.input`
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  transition: all linear 95ms;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;
