import axios from "axios";
import { isEmpty, lowerCase, upperCase } from "lodash";

export default async function formsService({
  financialInstitutionFriendlyName = process.env.GATSBY_FINANCIAL_INSTITUTION_FRIENDLY_NAME,
  securedFormFriendlyName = "",
  hostname = window.location.hostname,
  formData = {},
  type = "GET",
  urlBase = process.env.GATSBY_API_URL,
} = {}) {
  try {
    const isGet = /get/gi.test(type);

    const isValidRequestMethod = /(get|post)/gi.test(type);

    if (!isValidRequestMethod) {
      return {
        error: {
          message: `Method "${upperCase(type)}" is not supported by this service.`,
        },
      };
    }

    if (!isGet && isEmpty(formData)) {
      return {
        message: "You cannot submit an empty form",
      };
    }

    if (isEmpty(securedFormFriendlyName)) {
      return {
        error: { message: "Secured form securedFormFriendlyName name is required" },
      };
    }

    if (isEmpty(hostname)) {
      return {
        error: { message: "Secured form hostname name is required" },
      };
    }

    if (isEmpty(financialInstitutionFriendlyName)) {
      return {
        error: {
          message: "Secured form financialInstitutionFriendlyName name is required",
        },
      };
    }

    const method = axios[lowerCase(type)];

    const url = isGet
      ? `${urlBase}/secured-form?securedFormFriendlyName=${securedFormFriendlyName}&hostname=${hostname}&financialInstitutionFriendlyName=${financialInstitutionFriendlyName}`
      : `${urlBase}/financial-institution-secured-form-customer`;

    const options = {
      financialInstitutionFriendlyName,
      securedFormFriendlyName,
      hostname,
      securedFormValues: formData,
    };

    const response = await method(url, !isGet ? options : undefined);

    return response.data;
  } catch (error) {
    console.log(error);

    if (
      !isEmpty(error) &&
      error.response !== undefined &&
      error.response.status !== undefined &&
      error.message !== undefined
    ) {
      return {
        success: false,
        error: {
          metaData: error,
          fetchMessage: error.message,
          fetchStatus: error.response.status,
          message: "An unexpected error occurred. The form was not loaded.",
        },
      };
    }

    return {
      success: false,
      error: {
        metaData: {},
        fetchMessage: "The request has failed, please check the endpoint or other parameters.",
        fetchStatus: 404,
        message:
          "An unknown error occurred when requesting information, please contact technical support.",
      },
    };
  }
}
