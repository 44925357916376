import React, { forwardRef } from "react";
import styled from "styled-components";
import { lowerCase, random } from "lodash";

import { useInput } from "lib/@forms/hooks";
import { getColor } from "lib/@forms/theme";

function CheckBox(
  {
    formik = undefined /** formik: Formik instance */,
    keyName = "" /** string|number */,
    isRequired = false,
    disable = false,
    type = "radio",
    id = "",
    className = "",
    title = "",
    data = [],
    direction = "vertical" || "horizontal",
    onChange = undefined /** (value = "") => null */,
  },
  ref,
) {
  const $id = id || random(0, 99999999);

  const { payload, actions } = useInput({
    type,
    formik,
    keyName,
    isRequired,
    data,
    disable,
    label: title,
    onChange,
  });

  let $direction = "row";

  if (/vertical/gi.test(direction)) {
    $direction = "col";
  }

  return (
    <InputContainer className={className}>
      <span className="tw-font-medium bl-text-xs">{title}</span>

      <label
        className={`tw-flex tw-flex-${$direction} tw-mr-6 tw-items-start`}
        htmlFor={$id}
        role="group"
      >
        {payload.options.map(item => {
          const itemName = `${$id}-${lowerCase(item.label)}`;

          return (
            <div key={itemName} className="tw-mr-6 tw-mb-2">
              <div className="tw-flex tw-inline-flex tw-items-center tw-relative tw-pl-8">
                <Svg
                  className="tw-fill-current tw-hidden bl-text-primary tw-pointer-events-none"
                  viewBox="0 0 20 20"
                  active={item.isChecked}
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </Svg>

                <CheckBoxInput
                  ref={ref}
                  name={$id}
                  id={itemName}
                  className="tw-form-checkbox"
                  type="checkbox"
                  checked={item.isChecked}
                  value={item.label}
                  onBlur={actions.onBlur}
                  onClick={actions.onClick}
                  onChange={event => {
                    if (onChange !== undefined) onChange(event);
                    actions.handleChangeValue(event);
                  }}
                />

                <span className="tw-ml-2 tw-font-medium bl-text-xs">{item.label}</span>
              </div>
            </div>
          );
        })}
      </label>
    </InputContainer>
  );
}

/** Components */
const InputContainer = styled.div`
  display: block;
`;

const Svg = styled.svg`
  display: ${({ active = false }) => (active ? "block" : "none")};
  z-index: 5;
  position: absolute;
  left: 4px;
  bottom: 5px;
  width: ${({ size = 15 }) => size}px;
  height: ${({ size = 15 }) => size}px;
  color: ${({ active = false }) => (active ? "white" : "transparent")};
`;

const CheckBoxInput = styled.input`
  position: absolute;
  z-index: 4;
  left: 0;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);

  &:checked + svg {
    display: block;
  }

  &:after {
    width: ${({ size = 25 }) => size}px;
    height: ${({ size = 25 }) => size}px;
    border-radius: ${({ size = 25 }) => size * 0.25}px;
    top: -2px;
    left: -1px;
    position: relative;
    top: ${({ size = 25 }) => size * -0.25}px;
    background-color: ${({ disableColor = "#d1d3d1" }) => disableColor};
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }

  &:checked:after {
    width: ${({ size = 25 }) => size}px;
    height: ${({ size = 25 }) => size}px;
    border-radius: ${({ size = 25 }) => size * 0.25}px;
    top: -2px;
    left: -1px;
    position: relative;
    top: ${({ size = 25 }) => size * -0.25}px;
    background-color: ${getColor({ success: true, withBorder: true })};
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
`;

export default forwardRef(CheckBox);
