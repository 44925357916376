import React from "react";

import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

function ContentBox({ title, children }) {
  return (
    <section>
      <h3 className="bl-font-secondary bl-font-medium bl-text-grayscale bl-text-xl tw-my-8">
        {title}
      </h3>
      <div className="bl-text-grayscale bl-text-lg">{children}</div>
    </section>
  );
}

export default withErrorBoundaryHOC(ContentBox);
