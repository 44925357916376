import styled from "styled-components";

export default styled.select`
  background: transparent;
  padding: ${({ hasData = false }) => (hasData ? 15 : 0)}px 0 0 0;
  outline: none;
  box-shadow: none;
  transition: all linear 125ms;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;
