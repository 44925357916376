import React, { useState } from "react";
import { snakeCase } from "lodash";

import { Input, Button } from "lib/@cms/components/primitive";

function Login() {
  const [query, setQuery] = useState({
    cu: snakeCase(process.env.GATSBY_CU_NAME).toUpperCase(),
    btnLogin: "login",
  });

  function onSubmitHandler(e) {
    e.preventDefault();

    window.location.href = `${process.env.GATSBY_LOGIN_URL}?${formatQueryUrl(query)}`;
  }

  function formatQueryUrl(queryParams) {
    return Object.entries(queryParams)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&");
  }

  return (
    <div className="tw-max-w-full tw-w-96 tw-px-8 tw-py-10">
      <form onSubmit={onSubmitHandler}>
        <h1 className="tw-mb-10 bl-font-secondary tw-font-semibold bl-text-primary-180 bl-text-xl">
          Secure Login
        </h1>

        <Input
          type="text"
          name="username"
          label="Login ID"
          autoComplete="off"
          value={query.username}
          success={!!query.username}
          error={!query.username}
          className="tw-py-4 tw-px-3 tw-rounded-lg bl-text-grayscale-100 bl-bg-grayscale-6 tw-w-full tw-block focus:tw-outline-none tw-mb-1"
          onChange={({ target }) => {
            const username = target.value;
            setQuery({ ...query, username });
          }}
          isRequired
        />
        <div className="tw-flex tw-justify-end tw-mb-8">
          <a href={process.env.GATSBY_LOGIN_FORGOT_LOGIN_ID} className="bl-text-grayscale-50">
            Forgot Login ID?
          </a>
        </div>

        <Button
          fill={Button.fill.FILLED}
          className="tw-w-full"
          disabled={!query.username}
          type="submit"
        >
          Login
        </Button>

        <div className="tw-flex tw-justify-center tw-my-4">
          <a href={process.env.GATSBY_LOGIN_ENROLL_NOW} className="bl-text-grayscale-50">
            New to digital banking? Enroll now
          </a>
        </div>
      </form>
    </div>
  );
}

export default Login;
