import React from "react";
import { Link } from "gatsby";

import Divider from "lib/@cms/components/primitive/Divider";
import Icon from "lib/@cms/components/primitive/Icon";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

function Footer({ data }) {
  return (
    <footer className="tw-pt-20 bl-bg-primary-180">
      <div className="tw-container tw-px-6 sm:tw-px-8">
        <LogoHome />
        <div className="tw-mt-14 tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between">
          {data && <InfoEnterprise data={data} />}
          {data && <Links data={data} />}
          <Bussiness data={data} />
        </div>
        <Divider className="bl-bg-grayscale-0 tw-opacity-25 tw-my-8" />

        <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between md:tw-items-center tw-py-6">
          {data && <Enterprise data={data} />}
          <CorporateImage />
        </div>
      </div>

      {data && <BottomSection data={data} />}
    </footer>
  );
}

export default withErrorBoundaryHOC(Footer, {
  FallbackComponent: Footer,
});

// --- Components ---

function LogoHome() {
  return (
    <Link to="/" className="tw-w-full">
      <img src="/images/logo-white.svg" alt="Footer logo" className="tw-w-36 tw-h-6 tw-pr-10" />
    </Link>
  );
}

function InfoEnterprise({ data }) {
  return (
    <div className="md:tw-w-64">
      {data.Office.map((office, index1) => (
        <React.Fragment key={`InfoEnterprise-Office-element-${index1}`}>
          <div className="tw-w-36 bl-text-grayscale-0 bl-text-xs tw-mb-4">
            <p className="tw-font-bold tw-mb-1">{office.name}</p>
            <p className="tw-leading-none">{office.direction1}</p>
          </div>
          <div className="bl-text-grayscale-0 bl-text-xs tw-mb-4">
            {office.OfficeItem?.map((element, index2) => {
              return (
                <div key={`InfoEnterprise-OfficeItem-element-${index2}`} className="tw-flex">
                  <p className="tw-font-bold tw-mr-2">{element.name}</p>
                  <p>{element.description}</p>
                </div>
              );
            })}
          </div>
          <div className="bl-text-grayscale-0 bl-text-xs tw-mb-8">
            {office.OfficeItemTwo?.map((element, index2) => {
              return (
                <div key={`InfoEnterprise-OfficeItemTwo-element-${index2}`} className="tw-flex">
                  <p className="tw-font-bold tw-mr-2">{element.name}</p>
                  <p>{element.description}</p>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

function Links({ data }) {
  return (
    <div className="lg:tw-flex tw-max-w-48 lg:tw-max-w-96">
      {data.Links.map((element, index) => (
        <LinkItem key={`Links-element-${index}`} data={element} />
      ))}
    </div>
  );
}

function LinkItem({ data }) {
  return (
    <div className="bl-text-grayscale-0 bl-text-xs tw-mb-4 md:tw-pl-4 tw-pr-3">
      <h3 className="tw-font-bold tw-mb-2">{data.title}</h3>
      {data.linkItem.map((element, index) => {
        return (
          <a
            key={`LinkItem-linkItem-element-${index}`}
            href={element.url}
            className="tw-leading-6 md:tw-leading-tight tw-block  hover:tw-opacity-50"
          >
            {element.label}
          </a>
        );
      })}
    </div>
  );
}

function Bussiness({ data }) {
  return (
    <div className="tw-w-64 md:tw-w-48 lg:tw-w-64 tw-pt-8 md:tw-pt-0 tw-flex tw-flex-col md:tw-items-end">
      <div className="tw-relative">
        <a className="tw-absolute tw-h-full tw-w-36" href="https://www.ncua.gov" target="_blank" rel="noreferrer" />
        <img
          className="tw-w-36 tw-object-cover"
          src="/images/layout/footer/ncua.svg"
          alt="NCUA"
          title="Federally Insured by NCUA"
        />
      </div>
      <img
        className="tw-w-16 tw-object-cover tw-my-6"
        src="/images/layout/footer/equal-housing.svg"
        alt="Housing"
      />
      <div className="bl-text-grayscale-0 bl-text-xxs tw-mt-0 md:tw-text-right tw-opacity-50">
        {data && <p className="tw-leading-4 tw-m-0">{data.Brand.iconSubText}</p>}
      </div>
    </div>
  );
}

function Enterprise({ data }) {
  return (
    <div className="bl-text-grayscale-0 bl-text-xxs tw-opacity-50 tw-w-72 md:tw-w-96 tw-mb-8 md:tw-mb-0">
      <p className="tw-leading-4">{data.Brand.description}</p>
    </div>
  );
}

function CorporateImage() {
  return (
    <div className="tw-w-40 tw-text-right">
      <p className="bl-text-grayscale-0 bl-text-xxs tw-text-left md:tw-text-right">Powered by:</p>
      <img
        className="tw-h-auto tw-object-cover tw-mr-auto md:tw-ml-auto md:tw-mr-0"
        src="/images/layout/footer/blossom-logo.png"
        alt="Blossom Logo"
        width="112"
      />
    </div>
  );
}

function BottomSection({ data }) {
  return (
    <div className="bl-bg-grayscale-100 tw-py-6 tw-mt-10">
      <div className="tw-container tw-px-6 sm:tw-px-8 tw-flex tw-flex-col tw-items-center md:tw-flex-row md:tw-justify-between tw-opacity-50">
        <p className="tw-m-0 bl-text-grayscale-0 bl-text-xxs tw-py-2 sm:tw-py-0">
          {data.Bottom.leftText} {new Date().getFullYear()}
        </p>
        <div className="md:tw-flex md:tw-h-5 md:tw-h-auto md:tw-mt-0 md:tw-items-center">
          <div className="tw-flex tw-items-center">
            <IconCard href={data.Bottom.androidUrl} icon={Icon.icon.ANDROID} />
            <IconCard href={data.Bottom.appleUrl} icon={Icon.icon.APPLE} />
          </div>
          {/*
          <p className="tw-m-0 bl-text-grayscale-0 bl-text-xxs tw-mr-4 tw-hidden md:tw-block">
            {data.Bottom.rightText}
          </p>
          <Divider
            variant={Divider.variant.VERTICAL}
            className="tw-mx-6 bl-bg-grayscale-0 tw-hidden md:tw-block tw-h-5"
          />
          <div className="tw-flex tw-items-center">
            <IconCard href={data.Bottom.facebookUrl} icon={Icon.icon.FACEBOOK} />
            <IconCard href={data.Bottom.twitterUrl} icon={Icon.icon.TWITTER} />
          </div>
          */}
        </div>
      </div>
    </div>
  );
}

function IconCard({ href, icon }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="tw-flex tw-align-center tw-m-3 md:tw-m-0 md:tw-mx-2"
    >
      <Icon icon={icon} size="24" stroke="white" fill="white" />
    </a>
  );
}
