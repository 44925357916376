import { useState } from "react";

function useModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function closeModal() {
    setIsModalVisible(false);
  }

  function showModal() {
    setIsModalVisible(true);
  }

  return { isModalVisible, closeModal, showModal };
}

export default useModal;
