import { kebabCase } from "lodash";

/*
  - pageName: This prop is only useful for avista pages or for about-us and contact-us collection types
  - pageData: This prop avoids fetch data from strapi. You are passing the data object instead
  - dynamicPath: This prop search for dynamic pages (Custom-pages)
  - isCachedPage: Related to react-query and cache system
*/
export default async function fetchCMSPage({ isCachedPage, ...rest }) {
  try {
    const [header, footer, page] = await fetchData(rest);

    return {
      fallback: false,
      layout: {
        Header: header,
        Footer: footer,
      },
      page,
    };
  } catch (error) {
    console.log(error);

    if (isCachedPage) {
      return Promise.reject(error);
    }

    return {
      fallback: true,
      layout: {
        Header: {
          loginButtonText: "Login",
          NavBarItems: [
            { id: 1, url: "/about-us", text: "About" },
            { id: 2, url: "/contact-us", text: "Contact Us" },
          ],
        },
        Footer: undefined,
      },
      page: undefined,
    };
  }
}

function fetchData({ pageName, pageData, dynamicPage }) {
  if (pageData) {
    return [pageData.layout.Header, pageData.layout.Footer, pageData.page];
  }

  const CREDIT_UNION = kebabCase(process.env.GATSBY_CU_NAME);

  if (CREDIT_UNION === "avista") {
    return Promise.all([
      fetch(`${process.env.GATSBY_CMS_URL}/avista-header`, {
        method: "GET",
      }).then(r => r.json()),
      fetch(`${process.env.GATSBY_CMS_URL}/avista-footer`, {
        method: "GET",
      }).then(r => r.json()),
      fetch(`${process.env.GATSBY_CMS_URL}/avista-${pageName}`, {
        method: "GET",
      }).then(r => r.json()),
    ]);
  }

  return Promise.all([
    fetchCollectionType({ collectionTypeName: "cu-headers", CREDIT_UNION }),
    fetchCollectionType({ collectionTypeName: "cu-footers", CREDIT_UNION }),
    fetchCollectionType({ collectionTypeName: pageName, dynamicPage, CREDIT_UNION }),
  ]);
}

function fetchCollectionType({ collectionTypeName, dynamicPage, CREDIT_UNION }) {
  if (dynamicPage) {
    // TODO: Filter by CREDIT_UNION and CustomPageRoute.UrlRoute
  }

  return fetch(`${process.env.GATSBY_CMS_URL}/${collectionTypeName}`, {
    method: "GET",
  })
    .then(r => r.json())
    .then(r => {
      const collectionTypeItem = r.find(page =>
        page.CreditUnion.CREDIT_UNION === CREDIT_UNION ? page : [],
      );

      if (!collectionTypeItem) throw new Error("CollectionType item not found");

      return collectionTypeItem;
    });
}
