import styled from "styled-components";

export default styled.div`
  &,
  * {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    min-width: 0;
    max-width: 0;
    height: 0;
    min-width: 0;
    max-width: 0;
    z-index: -100;
  }
`;
