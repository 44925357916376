import React from "react";
import { lowerCase, snakeCase } from "lodash";

import { INPUT_GENERATOR_TYPES } from "lib/@forms/types/pages/forms";

import Dateline from "./Dateline";
import Input from "./Input";
import Radio from "./Radio";
import Select from "./Select";
import CheckBox from "./CheckBox";
import Textarea from "./Textarea";
import ReCaptcha from "./ReCaptcha";

export default function InputGenerator({ type = "", ...otherProps }) {
  const { id, keyName, formik, data, minLength, maxLength } = otherProps;

  const typeFormatted = lowerCase(type);
  const typeSnakeCase = snakeCase(type).toUpperCase();

  const commonProps = {
    id,
    keyName,
    formik,
    data,
    minLength,
    maxLength,
    type: typeFormatted,
  };

  const componentProps = { ...otherProps.props, ...commonProps };

  switch (typeSnakeCase) {
    case INPUT_GENERATOR_TYPES.SELECT:
      return <Select {...componentProps} />;

    case INPUT_GENERATOR_TYPES.TEXTAREA:
      return <Textarea {...componentProps} />;

    case INPUT_GENERATOR_TYPES.TEXT:
      return <Input {...componentProps} />;

    case INPUT_GENERATOR_TYPES.EMAIL:
      return <Input {...componentProps} />;

    case INPUT_GENERATOR_TYPES.NUMBER:
      return <Input {...componentProps} />;

    case INPUT_GENERATOR_TYPES.DATELINE:
      return <Dateline {...componentProps} />;

    case INPUT_GENERATOR_TYPES.RADIO:
      return <Radio {...componentProps} />;

    case INPUT_GENERATOR_TYPES.CHECKBOX:
      return <CheckBox {...componentProps} />;

    case INPUT_GENERATOR_TYPES.RE_CAPTCHA:
      return <ReCaptcha {...componentProps} />;

    default:
      return null;
  }
}
