export function isExternalURL(url) {
  if (!url) return false;

  let result = false;
  const currentDomain = `${window.location.protocol}//${window.location.host}`;
  const links = url.match(/(https?:\/\/[\w\d.-]+)/gi) || [];
  const regex = new RegExp(`^${currentDomain}`, "gi");

  links.forEach(link => {
    if (link.match(regex) === null) {
      result = true;
    }
  });

  return result;
}
