import React, { useState, useEffect } from "react";

import useModal from "lib/@cms/hooks/useModal";
import { isExternalURL } from "lib/@cms/utils/misc";

import ConfirmationModal from "./ConfirmationModal";

export default function SpeedBump({ data }) {
  const { isModalVisible, showModal, closeModal } = useModal();
  const [externalURL, setExternalURL] = useState("");

  useEffect(() => {
    function onClickHandler(e) {
      const target = e.target || e.srcElement;

      if (e.target.tagName !== "A") return;

      while (target) {
        if (target instanceof HTMLAnchorElement) {
          const url = target.getAttribute("href");

          if (isExternalURL(url) && !insideWhitelist(url)) {
            e.preventDefault();
            setExternalURL(url);
            showModal();
          }

          break;
        }
      }
    }

    if (document.addEventListener) {
      document.addEventListener("click", onClickHandler, false);
    } else {
      document.attachEvent("onclick", onClickHandler);
    }
  }, []);

  function insideWhitelist(url) {
    const whitelist = (process.env.GATSBY_SPEEDBUMP_WHITELIST_URL || "").split(",");

    let inside = false;

    whitelist.forEach(item => {
      if (url.indexOf(item) !== -1) inside = true;
    });

    return inside;
  }

  return (
    <ConfirmationModal
      message={data.speedBumpDisclaimer}
      isModalVisible={isModalVisible}
      showModal={showModal}
      closeModal={closeModal}
      onAccept={() => {
        window.location.href = externalURL;
      }}
    />
  );
}
