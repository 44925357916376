import React from "react";
import classnames from "classnames";

function Icon({ icon, size = 24, className }) {
  return (
    <img src={icon} width={size} alt="Icon" className={classnames("tw-inline-block", className)} />
  );
}

Icon.icon = {
  SEARCH: "/images/icons/search.svg",
  ENVELOPE: "/images/icons/envelope.svg",
  PHONE: "/images/icons/phone.svg",
  FACEBOOK_SIMPLE: "/images/icons/facebook-simple.svg",
  INSTAGRAM: "/images/icons/instagram.svg",
  FACEBOOK: "/images/icons/facebook.svg",
  PIN: "/images/icons/pin.svg",
  ANDROID: "/images/icons/android.svg",
  APPLE: "/images/icons/apple.svg",
  TWITTER: "/images/icons/twitter.svg",
  NAVIGATION_MENU: "/images/icons/navigation-menu.svg",
  REMOVE: "/images/icons/remove.svg",
  ARROW_DOWN_1: "/images/icons/arrow-down-1.svg",
  ARROW_UP_1: "/images/icons/arrow-up-1.svg",
};

export default Icon;
