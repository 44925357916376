import React, { useEffect, useState } from "react";
import { isEmpty, camelCase } from "lodash";
import InputGenerator from "./InputGenerator";

export default function FormLinked({
  formik /** formik: Formik Instance */,
  relationName = "",
  // acceptedValue = "",
  form: components = [],
  inputs = [],
  cols = 1,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const currentComponent = inputs.find(({ id: compId }) => {
      const isThis = camelCase(compId) === camelCase(relationName);

      return isThis;
    });

    const formikValue = formik ? formik.values[`_${relationName}`] : "";
    const hasData = !!formikValue && !isEmpty(currentComponent);

    if (
      !!currentComponent &&
      !!currentComponent.type &&
      /radio/i.test(currentComponent.type) &&
      !!formikValue
    ) {
      if (/yes/i.test(formikValue)) setShow(true);
      else if (/no/i.test(formikValue)) setShow(false);
      else setShow(hasData);
    }
  }, [inputs, formik.values, relationName]);

  if (!show) return null;

  return (
    <React.Fragment>
      {components.map(comp => (
        <div
          key={`${comp.key}-${comp.id}`}
          className={`tw-p-0 tw-col-span-1 tw-grid tw-grid-cols-${cols} tw-gap-4`}
        >
          {comp.inputs.map(input => (
            <div key={input.keyName} className="tw-grid tw-grid-cols-1">
              <InputGenerator {...input} formik={formik} />
            </div>
          ))}
        </div>
      ))}
    </React.Fragment>
  );
}
