import { useState } from "react";

function useForm(initialState = {}) {
  const [values, setValues] = useState(initialState);

  const reset = () => setValues(initialState);

  const handleInputChange = ({ key: name = "", value: data = "" }) => {
    setValues({
      ...values,
      [name]: data,
    });
  };

  return { values, reset, handleInputChange };
}

export default useForm;
