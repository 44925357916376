import React from "react";
import Helmet from "react-helmet";

import SpeedBump from "lib/@cms/components/shared/SpeedBump";
import useFetch from "lib/@cms/hooks/useFetch";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";
import getQueryClient from "lib/@cms/store";
import fetchCMSPage from "lib/@cms/utils/cms";

import Header from "./Header";
import Footer from "./Footer";
import Error from "./Error";

function Page({ pathname, cms = {}, children }) {
  const { isLoading, data, error } = useFetch(cms.pageId || cms.dynamicPath || pathname, () => {
    const key = cms.pageId || cms.dynamicPath || pathname;
    const cachedPage = getQueryClient().getQueryCache().find(key);

    return fetchCMSPage({
      pageName: key,
      dynamicPath: cms.dynamicPath,
      pageData: cms.pageData,
      isCachedPage: !!(cachedPage && cachedPage.state.data && !cachedPage.state.data.fallback),
    });
  });

  const isErrorAndPageIsNotCached = error && !data;
  const isErrorButFallbackDataWillDisplayed = !error && data && data.fallback;

  return (
    <Layout
      pathname={pathname}
      {...(data ? { header: data.layout.Header, footer: data.layout.Footer } : {})}
    >
      {isLoading ? (
        <Loader />
      ) : isErrorAndPageIsNotCached === true || isErrorButFallbackDataWillDisplayed === true ? (
        <React.Fragment>
          <SEO pathname={pathname} />
          <ErrorView />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SEO
            title={data.page.MetaData.title}
            description={data.page.MetaData.description}
            noRobots={data.page.MetaData.noRobots}
            pathname={pathname}
          />
          <SpeedBump data={data.layout.Header} />
          <Content render={children} data={data} />
        </React.Fragment>
      )}
    </Layout>
  );
}

export default Page;

// --- Components ---

function Layout({ pathname, header, footer, children }) {
  return (
    <main className="tw-min-h-screen">
      <Header pathname={pathname} data={header} />
      <div className="tw-pt-14 lg:tw-pt-0">{children}</div>
      <Footer data={footer} />
    </main>
  );
}

function Loader() {
  return (
    <div className="bl-text-primary bl-text-2xl tw-h-screen tw-flex tw-justify-center tw-items-center">
      <img src="/images/icons/spinner.svg" alt="Loader animation" className="tw-animate-spin" />
    </div>
  );
}

function ErrorView() {
  return <Error title="Oops, could not find the page" />;
}

function SEO({ title, description = "", noRobots = false, pathname }) {
  const URL = `${process.env.GATSBY_WEBSITE_URL}/${pathname}`;
  const IMAGE = `${process.env.GATSBY_WEBSITE_URL}/images/logo.
  svg`;
  const TITLE = `${process.env.GATSBY_CU_NAME}${title ? ` - ${title}` : ""}`;

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={TITLE} titleTemplate={TITLE}>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

      <meta name="title" content={TITLE} />
      <meta name="description" content={description} />

      {noRobots && <meta name="robots" content="noindex,nofollow" />}

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={TITLE} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={IMAGE} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={URL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={IMAGE} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={IMAGE} />
      <link rel="canonical" href={URL} />
    </Helmet>
  );
}

const Content = withErrorBoundaryHOC(
  ({ render, data }) => {
    return <React.Fragment>{render(data.page)}</React.Fragment>;
  },
  {
    FallbackComponent: ErrorView,
  },
);
