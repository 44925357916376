import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export default styled(ToastContainer)`
  /** Used to define container behavior: width, position: fixed etc... **/
  & {
  }

  /** Used to define the position of the ToastContainer **/

  &--top-left {
  }

  &--top-center {
  }

  &--top-right {
  }

  &--bottom-left {
  }

  &--bottom-center {
  }

  &--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
  }

  .Toastify__toast--rtl {
  }

  .Toastify__toast--dark {
  }

  .Toastify__toast--default {
  }

  .Toastify__toast--info {
  }

  .Toastify__toast--success {
    background: #3fc78e;
  }

  .Toastify__toast--warning {
  }

  .Toastify__toast--error {
    background: #de3232;
  }

  .Toastify__toast-body {
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }

  .Toastify__close-button--default {
  }

  .Toastify__close-button > svg {
  }

  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
  }

  .Toastify__progress-bar--animated {
  }

  .Toastify__progress-bar--controlled {
  }

  .Toastify__progress-bar--rtl {
  }

  .Toastify__progress-bar--default {
  }

  .Toastify__progress-bar--dark {
  }
`;
