import React from "react";
import classnames from "classnames";

const VARIANTS = {
  VERTICAL: "VERTICAL",
  HORIZONTAL: "HORIZONTAL",
};

function Divider({ variant = VARIANTS.HORIZONTAL, className }) {
  if (variant === VARIANTS.VERTICAL) {
    return <VerticalDivider className={className} />;
  }

  return <HorizontalDivider className={className} />;
}

Divider.variant = VARIANTS;

export default Divider;

// --- Components ---

function VerticalDivider({ className }) {
  return <hr className={classnames("tw-w-px", className)} />;
}

function HorizontalDivider({ className }) {
  return <hr className={classnames("tw-border-0 tw-h-px", className)} />;
}
