import React, { memo, useRef } from "react";
import { random } from "lodash";
import {
  Textarea as TextareaInput,
  InputBox,
  TextareaContainer,
  InputLabel,
  InputLabelError,
  InputLabelText,
} from "lib/@forms/components/styled";
import { useInput } from "lib/@forms/hooks";
import { INPUT, INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

function Textarea({
  formik = undefined /** formik: Formik instance */,
  keyName = "" /** string|number */,
  name = undefined /** string */,
  autoComplete = undefined /** string */,
  className = "",
  isRequired = false,
  disable = false,
  success: $success = undefined /** Boolean */,
  error: $error = undefined /** Boolean */,
  minHeight = 100,
  maxHeight = 200,
  label = "",
  type = "text",
  value: $value = undefined,
  minLength = undefined /** minLength: Number */,
  maxLength = undefined /** maxLength: Number */,
  onChange = undefined /** (value = "") => null */,
}) {
  const ref = useRef(null);

  const { payload, actions } = useInput({
    type,
    keyName,
    isRequired,
    disable,
    label,
    onChange,
    formik,
    minLength,
    maxLength,
  });

  const { formikValue, formikField = {}, formikError, isTouched } = payload;

  const $id = keyName || random(0, 99999999);

  let value = formik ? formikValue || "" : payload.value || "";

  let success = (!formikError && !!isTouched && !!value) || (!!value && isRequired && !!isTouched);

  let error = (!!formikError && !!isTouched) || (!value && isRequired && !!isTouched);

  if ($value !== undefined) value = $value;
  if ($success !== undefined) success = $success && !!isTouched;
  if ($error !== undefined) error = $error && !!isTouched;

  return (
    <TextareaContainer
      htmlFor={name || $id}
      onBlur={actions.onBlur}
      onClick={actions.onClick}
      success={success}
      error={error}
      like={INPUT}
      minHeight={minHeight}
      maxHeight={maxHeight}
      className={className}
    >
      <InputLabel top={value ? 5 : undefined}>
        <InputLabelText
          success={success}
          error={error}
          like={INPUT_TEXT}
          className="bl-text-xxs tw-font-medium"
        >
          {`${label}${!isRequired ? " (Optional)" : ""}`}
        </InputLabelText>
      </InputLabel>

      <InputBox hasData={false}>
        <TextareaInput
          ref={ref}
          {...formikField}
          autoComplete={autoComplete}
          id={name || $id}
          name={name || $id}
          disabled={disable}
          type={type}
          success={success}
          error={error}
          className="bl-text-sm tw-font-medium bl-text-grayscale-80"
          // placeholder={label}
          value={value}
          onChange={event => {
            if (onChange !== undefined) onChange(event);
            actions.handleChangeValue(event);
          }}
        />
      </InputBox>

      {!!error && (
        <InputLabelError bottom={error && ref && ref.current ? -20 : 0}>
          <InputLabelText error like={INPUT_TEXT} className="bl-text-xxs tw-font-medium">
            {formikError}
          </InputLabelText>
        </InputLabelError>
      )}
    </TextareaContainer>
  );
}

export default memo(Textarea);
