export const INPUT_GENERATOR_TYPES = {
  SELECT: "SELECT",
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA",
  DATELINE: "DATELINE",
  RADIO: "RADIO",
  CHECKBOX: "CHECKBOX",
  NUMBER: "NUMBER",
  EMAIL: "EMAIL",
  RE_CAPTCHA: "RE_CAPTCHA",
};
