import styled from "styled-components";

export default styled.div`
  position: ${({ noFloat = false }) => (!noFloat ? "absolute" : "")};
  bottom: ${({ noFloat = false, bottom = 5 }) => (!noFloat ? `${bottom}px` : undefined)};
  left: ${({ noFloat = false }) => (!noFloat ? "0.25rem" : undefined)};
  transition: all linear 95ms;
  //  white-space: nowrap;
  //  overflow: hidden;
  //  text-overflow: ellipsis;

  @media (max-width: 428px) {
    bottom: ${({ noFloat = false, bottom: position = -25, length = 0, maxLength = 27 }) => {
      const isVeryLong = length > maxLength;
      const multiplier = 1.6;

      if (isVeryLong) return `${position * multiplier}px`;

      if (!noFloat) return `${position}px`;

      return undefined;
    }};
  }
`;
