import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import hoistNonReactStatics from "hoist-non-react-statics";

function withErrorBoundaryHOC(Component, { FallbackComponent } = {}) {
  const WithErrorBoundaryBuilder = props => {
    const ComponentWithBoundary = withErrorBoundary(Component, {
      FallbackComponent: FallbackComponent || DefaultFallbackComponent,
      onError(error, info) {
        console.log(error);
        console.log(info);
      },
    });

    return <ComponentWithBoundary {...props} />;
  };

  WithErrorBoundaryBuilder.displayName = `withErrorBoundaryHOC(${
    Component.displayName || Component.name
  })`;

  return hoistNonReactStatics(WithErrorBoundaryBuilder, Component);
}

export default withErrorBoundaryHOC;

// --- Components ---

function DefaultFallbackComponent() {
  // TODO: Launch some error alert using something like Rollup
  return null;
}
