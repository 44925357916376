import React, { useState } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import useModal from "lib/@cms/hooks/useModal";

import Button from "lib/@cms/components/primitive/Button";
import Modal from "lib/@cms/components/primitive/Modal";
import Icon from "lib/@cms/components/primitive/Icon";

import Login from "./Login";
import Styles from "./Header.module.css";

function HeaderMobile({ data, pathname, className }) {
  const { isModalVisible, closeModal, showModal } = useModal();
  const [menuActive, setMenuActive] = useState(false);

  return (
    <header
      className={classnames(
        "tw-fixed tw-z-50 tw-w-full tw-font-normal tw-h-full",
        Styles.header,
        Styles.headerHeight,
        menuActive && "tw-min-h-screen tw-overflow-y-scroll",
        className,
      )}
    >
      <nav className={classnames("tw-z-50", !menuActive && "tw-h-full")}>
        <div className="tw-h-full tw-px-6">
          <div
            className={classnames(
              "tw-w-full tw-flex tw-justify-between tw-items-center",
              Styles.headerHeight,
            )}
          >
            <Link className="tw-mr-6 tw-py-4" to="/">
              <img src="/images/logo.svg" alt="Header logo" className={Styles.logo} />
            </Link>

            <div className="tw-float-right tw-flex tw-items-center tw-py-2 tw-rounded">
              {data && data.loginButtonText && !menuActive && (
                <Button
                  fill={Button.fill.FILLED}
                  styleType={Button.styleType.PILL}
                  className="tw-mr-3"
                  onClick={() => {
                    showModal();
                    setMenuActive(false);
                  }}
                >
                  {data.loginButtonText}
                </Button>
              )}
              {data && (
                <button
                  type="button"
                  className="tw-float-right tw-flex tw-items-center tw-px-3 tw-py-2 hover:bl-border-grayscale-0 "
                  onClick={() => setMenuActive(current => !current)}
                >
                  {menuActive ? (
                    <Icon icon={Icon.icon.REMOVE} />
                  ) : (
                    <Icon icon={Icon.icon.NAVIGATION_MENU} />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        {menuActive && data && <Dropdown data={data} pathname={pathname} />}

        <Modal show={isModalVisible} closeModal={closeModal}>
          <Login />
        </Modal>
      </nav>
    </header>
  );
}

export default HeaderMobile;

// --- Components ---

function Dropdown({ data, pathname }) {
  const [openedSubMenu, setOpenedSubMenu] = useState("");

  function toggleSubMenu(subMenuId) {
    return () => {
      setOpenedSubMenu(subMenuId === openedSubMenu ? "" : subMenuId);
    };
  }

  return (
    <div className="bl-font-secondary bl-text-grayscale-100 tw-mt-50 tw-pt-5 tw-pb-10 bl-bg-grayscale-0 tw-z-50 tw-w-full lg:tw-flex-grow">
      {data.NavBarItems.map((menuItem, index) => {
        const isLinkActive = menuItem.id === openedSubMenu;

        return (
          <div
            key={`Dropdown-menuItem-${index}`}
            className={classnames("lg:tw-mt-0", isLinkActive ? "tw-mt-4" : "tw-mt-8")}
          >
            {menuItem.subLinks && menuItem.subLinks.length > 0 ? (
              <LinkWithSubLinks
                isLinkActive={isLinkActive}
                id={menuItem.id}
                pathname={pathname}
                text={menuItem.text}
                subLinks={menuItem.subLinks}
                url={menuItem.url}
                onClick={toggleSubMenu}
              />
            ) : (
              <Link
                to={menuItem.url}
                className={classnames(
                  "tw-block lg:tw-inline-block lg:tw-mt-0 bl-text-md tw-pl-4",
                  `/${pathname}` === menuItem.url &&
                    "lg:tw-pt-1 tw-border-l-2 tw-font-bold bl-border-primary",
                )}
              >
                {menuItem.text}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
}

function LinkWithSubLinks({ id, pathname, text, subLinks, isLinkActive, onClick }) {
  return (
    <div
      className={classnames(
        "tw-text-left",
        isLinkActive && "bl-bg-primary-6 tw-border-l-2 bl-border-primary tw-py-4",
      )}
    >
      <button
        type="button"
        className={classnames(
          "focus:tw-outline-none tw-text-left hover:bl-text-primary bl-text-md tw-pl-4",
          isLinkActive && "bl-text-primary tw-mb-2",
        )}
        onClick={onClick(id)}
        onKeyPress={onClick(id)}
      >
        <span className="tw-mr-4">{text}</span>
        <Icon size={16} icon={isLinkActive ? Icon.icon.ARROW_UP_1 : Icon.icon.ARROW_DOWN_1} />
      </button>
      {isLinkActive && <SubLinks pathname={pathname} subLinks={subLinks} />}
    </div>
  );
}

function SubLinks({ pathname, subLinks }) {
  return (
    <div className="tw-pt-6">
      {subLinks.map((subLink, i) => {
        const keys = Object.keys(subLink);

        return (
          <div key={`SubLinks-subLink-${i}`} className="tw-mb-10 last:tw-mb-0">
            {subLink.title && (
              <p className="tw-pl-4 bl-text-base bl-text-grayscale-50 tw-mb-6">{subLink.title}</p>
            )}

            {keys.map((key, index) => {
              const url = subLink[`url${index}`];
              const text = subLink[`text${index}`];

              if (!url || !text) {
                return null;
              }

              return (
                <div
                  key={`SubLink-key-${key}`}
                  className="tw-w-full tw-pb-6 last:tw-pb-0 tw-pl-4 bl-text-md"
                >
                  <Link
                    to={url}
                    className={classnames(
                      "bl-font-primary",
                      `/${pathname}` === url ? "bl-text-primary" : "bl-text-grayscale-80",
                    )}
                  >
                    {text}
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
