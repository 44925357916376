import React from "react";

function Error({ title, children }) {
  return (
    <div className="bl-bg-grayscale-6 tw-min-h-screen tw-flex tw-flex-col tw-justify-center tw-p-4">
      <div className="tw-p-4 tw-max-w-md tw-mx-auto bl-bg-grayscale-0 tw-shadow-lg tw-text-center tw-rounded-lg">
        <h1 className="bl-text-2xl tw-font-bold tw-mb-6">{title}</h1>

        <div>
          {children || (
            <React.Fragment>
              <span>We could not find the page you are looking for.</span>
              <div>
                Go back to our home page by{" "}
                <button
                  type="button"
                  className="tw-font-bold tw-underline"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  clicking here
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default Error;
