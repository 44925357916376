import styled from "styled-components";

export default styled.textarea`
  background: transparent;
  width: 100%;
  min-height: ${({ minHeight = 100 }) => minHeight}px;
  max-height: ${({ maxHeight = 200 }) => maxHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  transition: all linear 95ms;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;
