import React from "react";

import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

const VARIANTS = {
  SIMPLE: "SIMPLE",
  H2: "H2",
  DESCRIPTION: "DESCRIPTION",
};

function Text({ variant, ...props }) {
  if (variant === VARIANTS.H2) {
    return <H2 {...props} />;
  }

  if (variant === VARIANTS.SIMPLE) {
    return <TextSimple {...props} />;
  }

  if (variant === VARIANTS.BODY) {
    return <TextBody {...props} />;
  }

  throw new Error(`Invalid Text variant: ${variant}`);
}

Text.variant = VARIANTS;

export default withErrorBoundaryHOC(Text);

// --- Components ---

function H2({ children, className }) {
  return <h3 className={`bl-grey-600 tw-weight-900 bl-text-5xl ${className}`}>{children}</h3>;
}

function TextSimple({ children, className }) {
  return (
    <div className="tw-w-full">
      <div className={`tw-rounded-xl bl-text-lg ${className}`}>
        <p>{children}</p>
      </div>
    </div>
  );
}

function TextBody({ children, className }) {
  return (
    <div className="tw-w-full">
      <div className={`tw-rounded-xl bl-text-lg ${className}`}>
        <p>{children}</p>
      </div>
    </div>
  );
}
