import React from "react";
import useModal from "lib/@cms/hooks/useModal";
import useClickOut from "lib/@cms/hooks/useClickOut";
import { Link } from "gatsby";
import classnames from "classnames";

import Button from "lib/@cms/components/primitive/Button";
import Modal from "lib/@cms/components/primitive/Modal";
import Icon from "lib/@cms/components/primitive/Icon";
import { isExternalURL } from "lib/@cms/utils/misc";

import Login from "./Login";
import Styles from "./Header.module.css";

function HeaderDesktop({ data, pathname, className }) {
  return (
    <header
      className={classnames(
        "tw-relative tw-font-normal tw-flex tw-flex-wrap",
        Styles.header,
        Styles.headerHeight,
        className,
      )}
    >
      <div className="tw-container tw-px-6 sm:tw-px-8 tw-flex tw-justify-between tw-items-center tw-h-full">
        <Link className="tw-mr-6" to="/">
          <img src="/images/logo.svg" alt="Header logo" className={Styles.logo} />
        </Link>
        {data && (
          <NavMenu
            pathname={pathname}
            menuItems={data.NavBarItems}
            loginButtonText={data.loginButtonText}
          />
        )}
      </div>
    </header>
  );
}

export default HeaderDesktop;

// --- Components ---

function NavMenu({ pathname, menuItems, loginButtonText }) {
  const { isModalVisible, closeModal, showModal } = useModal();

  return (
    <nav className="tw-w-full tw-hidden sm:tw-inline-flex tw-items-center tw-h-full tw-flex-1 tw-justify-between">
      <div className="tw-inline-flex tw-h-full">
        <NavItems pathname={pathname} menuItems={menuItems} />
      </div>

      <div className="tw-flex tw-items-center">
        {/* <Icon size={16} className="tw-mr-6" icon={Icon.icon.SEARCH} /> */}
        {loginButtonText && (
          <Button
            fill={Button.fill.FILLED}
            styleType={Button.styleType.PILL}
            onClick={() => showModal()}
          >
            {loginButtonText}
          </Button>
        )}
        <Modal show={isModalVisible} closeModal={closeModal}>
          <Login />
        </Modal>
      </div>
    </nav>
  );
}

function NavItems({ pathname, menuItems }) {
  return menuItems.map(menuItem => {
    if (menuItem.subLinks && menuItem.subLinks.length > 0) {
      return (
        <LinkWithSubLinks
          key={`LinkWithSubLinks-${menuItem.id}`}
          menuItem={menuItem}
          pathname={pathname}
        />
      );
    }

    return <LinkNormal key={`LinkNormal-${menuItem.id}`} menuItem={menuItem} pathname={pathname} />;
  });
}

function LinkNormal({ menuItem, pathname }) {
  if (isExternalURL(menuItem.url)) {
    return (
      <a
        href={menuItem.url}
        className={classnames(
          "bl-text-xs tw-h-full active:bl-text-primary tw-px-6 tw-flex tw-items-center tw-h-full hover:bl-bg-primary-6 tw-justify-center",
          `/${pathname}` === menuItem.url &&
            "tw-pb-1 tw-border-t-2 bl-text-primary tw-font-medium bl-border-primary",
        )}
      >
        {menuItem.text}
      </a>
    );
  }

  return (
    <Link
      to={menuItem.url}
      className={classnames(
        "bl-text-xs tw-h-full active:bl-text-primary tw-px-6 tw-flex tw-items-center tw-h-full hover:bl-bg-primary-6 tw-justify-center",
        `/${pathname}` === menuItem.url &&
          "tw-pb-1 tw-border-t-2 bl-text-primary tw-font-medium bl-border-primary",
      )}
    >
      {menuItem.text}
    </Link>
  );
}

function LinkWithSubLinks({ menuItem, pathname }) {
  const { ref, isVisible, setIsVisible } = useClickOut(false);

  function updateWhenClick() {
    setIsVisible(current => !current);
  }

  return (
    <div
      className={classnames(
        "tw-z-50 tw-align-text-top tw-w-48",
        isVisible && "bl-bg-primary-6 tw-border-solid bl-border-primary tw-border-t-2",
      )}
    >
      <div className="tw-inline-block tw-relative tw-h-full tw-w-full">
        <button
          ref={ref}
          onClick={updateWhenClick}
          type="button"
          className="bl-text-xs tw-h-full focus:tw-outline-none active:bl-text-primary tw-px-2 tw-flex tw-items-center tw-h-full hover:bl-text-grayscale-100 hover:bl-bg-primary-6 tw-w-full tw-justify-center"
        >
          <span className={classnames("tw-mr-2", isVisible && "bl-text-primary tw-font-medium")}>
            {menuItem.text}
          </span>
          <Icon size={12} icon={isVisible ? Icon.icon.ARROW_UP_1 : Icon.icon.ARROW_DOWN_1} />
        </button>
        {isVisible && <HeaderLinkDropdown menuItem={menuItem} pathname={pathname} />}
      </div>
    </div>
  );
}

function HeaderLinkDropdown({ menuItem, pathname }) {
  return (
    <div className="tw-px-8 tw-rounded-b bl-bg-primary-6 tw-flex tw-absolute tw-text-gray-700 group-hover:tw-block bl-text-sm">
      {menuItem.subLinks.map((items, i) => {
        const keys = Object.keys(items);

        return (
          <ul key={`HeaderLinkDropdown-items-${i}`} className="tw-py-10 tw-w-52">
            {items.title && (
              <span className="tw-py-2 tw-px-4 bl-font-primary tw-font-bold tw-block tw-whitespace-no-wrap">
                {items.title}
              </span>
            )}

            {keys.map((key, index) => {
              const url = items[`url${index}`];
              const text = items[`text${index}`];

              if (!url || !text) {
                return null;
              }

              return (
                <li key={`HeaderDropdownSubContainer-subItem-${key}`}>
                  <a
                    className={classnames(
                      "bl-font-primary tw-rounded-b tw-py-2 tw-px-4 tw-block tw-whitespace-no-wrap hover:bl-text-primary",
                      `/${pathname}` === url
                        ? "tw-font-bold bl-text-primary"
                        : "bl-text-grayscale-50",
                    )}
                    href={url}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
}
