import React from "react";

import Button from "lib/@cms/components/primitive/Button";
import Modal from "lib/@cms/components/primitive/Modal";

export default function ConfirmationModal({
  message = "",
  cancelBtnText = "Cancel",
  acceptBtnText = "Accept",
  isModalVisible = () => null,
  closeModal = () => null,
  onAccept = () => null,
}) {
  return (
    <Modal show={isModalVisible} closeModal={closeModal}>
      <div className="tw-max-w-md tw-relative tw-p-6 tw-flex-auto tw-mt-20 tw-pt-0">
        <p className="bl-text-md">{message}</p>
      </div>
      <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
        <Button
          styleType={Button.styleType.STANDARD}
          className="tw-mr-2 tw-flex-1"
          fill={Button.fill.OUTLINE_DARK}
          onClick={() => closeModal()}
        >
          {cancelBtnText}
        </Button>

        <Button
          styleType={Button.styleType.STANDARD}
          className="tw-ml-2 tw-flex-1"
          fill={Button.fill.FILLED}
          onClick={() => onAccept()}
        >
          {acceptBtnText}
        </Button>
      </div>
    </Modal>
  );
}
