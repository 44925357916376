import React from "react";
import { random } from "lodash";

import {
  CustomInput,
  InputContainer,
  InputLabel,
  InputLabelError,
  InputLabelText,
} from "lib/@forms/components/styled";
import { useInput } from "lib/@forms/hooks";
import { INPUT, INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

export default function Dateline({
  formik = undefined /** formik: Formik instance */,
  keyName = "" /** string|number */,
  // id = undefined, // string|number
  height = 50,
  isRequired = false,
  disable = false,
  label = "",
  type = "dateline",
  value: $value = undefined,
  onChange = undefined /** (value = "") => null */,
}) {
  const { payload, actions } = useInput({
    type,
    formik,
    keyName,
    isRequired,
    disable,
    label,
    onChange,
  });

  const { formikValue, formikError, isTouched } = payload;

  const $id = keyName || random(0, 99999999);

  const value = $value || formik ? formikValue || "" : value || "";

  const success =
    (!formikError && !!isTouched && !!value) || (!!value && isRequired && !!isTouched);

  const error = (!!formikError && !!isTouched) || (!value && isRequired && !!isTouched);

  return (
    <InputContainer
      htmlFor={$id}
      onBlur={actions.onBlur}
      onClick={actions.onClick}
      success={success}
      error={error}
      like={INPUT}
      height={height}
    >
      <InputLabel top>
        <InputLabelText
          success={success}
          error={error}
          like={INPUT_TEXT}
          className="bl-text-xxs tw-font-medium"
        >
          {label}

          {!isRequired && <span>{" (Optional)"}</span>}
        </InputLabelText>
      </InputLabel>

      <CustomInput
        id={$id}
        name={$id}
        disabled={disable}
        success={success}
        error={error}
        className="bl-text-sm tw-font-medium bl-text-grayscale-80 tw-pt-4"
        type="date"
        value={value}
        onChange={event => {
          if (onChange !== undefined) onChange(event);
          actions.handleChangeValue(event);
        }}
      />

      {!!isTouched ||
        (!!error && (
          <InputLabelError bottom={error ? -20 : 0}>
            <InputLabelText error like={INPUT_TEXT} className="bl-text-xxs tw-font-medium">
              {formikError}
            </InputLabelText>
          </InputLabelError>
        ))}
    </InputContainer>
  );
}
