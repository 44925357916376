import styled from "styled-components";

import { getColor } from "lib/@forms/theme";
import { INPUT, INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

const background = ({
  hiddenBg = false,
  like = INPUT || INPUT_TEXT,
  error = false,
  success = false,
  withBorder = false,
}) => {
  if (!hiddenBg) return getColor({ like, error, success, withBorder });

  return undefined;
};

const hoverBackground = ({
  hiddenBg = false,
  like = INPUT || INPUT_TEXT,
  error = false,
  withBorder = false,
}) => {
  if (!hiddenBg) {
    if (error) return getColor({ like, error: true, success: false, withBorder });

    return getColor({ like, error: false, success: true, withBorder });
  }

  return undefined;
};

const padding = ({ noPadding = false }) => (noPadding ? 0 : "0 1.25rem");

const height = ({ height: $height = 55 }) => $height;

const focusWithinBorderWidth = ({ hiddenBg = false }) => (hiddenBg ? 0 : 1);

const focusWithinBorderColor = ({
  like = INPUT || INPUT_TEXT,
  hiddenBg = false,
  error = false,
  success = false,
}) => hoverBackground({ hiddenBg, like, error, success, withBorder: true });

export default styled.label`
  background: ${background};
  border-radius: ${({ borderRadius = 10 }) => borderRadius}px;
  padding: ${padding};
  height: ${height}px;
  position: relative;
  display: grid;
  transition: all linear 100ms;

  &:focus-within {
    background: ${hoverBackground};
    border: solid ${focusWithinBorderWidth}px ${focusWithinBorderColor};
  }
`;
