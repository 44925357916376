import React from "react";

import useWindowDimensions from "lib/@cms/hooks/useWindowDimensions";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";
import { BREAKPOINTS } from "lib/@cms/styles";

import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

function Header({ data, pathname }) {
  const { width } = useWindowDimensions();

  if (width >= BREAKPOINTS.LARGE) {
    return <HeaderDesktop data={data} pathname={pathname} />;
  }

  return <HeaderMobile data={data} pathname={pathname} />;
}

export default withErrorBoundaryHOC(Header, {
  FallbackComponent: Header,
});
