import { useState } from "react";
import { isEmpty } from "lodash";

export default ({
  disable = false /**  disable: Boolean */,
  isSuccess = undefined /**  isSuccess: Boolean */,
  hasError = undefined /** hasError: Boolean */,
  formik = undefined /** formik: Formik Instance */,
  onChange = (/** value = "" */) => undefined,
  keyName = "" /** Unique key */,
  type = "text" /** text|number|email|textarea|select|dateline|radio */,
  data = [] /** Data is an Array only for input of select type */,
}) => {
  const formikValue = formik ? formik.values[keyName] : "";
  const formikError = formik ? formik.errors[keyName] : "";
  const isTouched = formik ? formik.touched[keyName] : false;

  const [value, setValue] = useState("");

  const [options, setOptions] = useState(data);

  const [currentOption, setCurrentOption] = useState({});

  const [focus, setFocus] = useState(false);

  const isText = /(text|number|email|textarea)/gi.test(type);

  const isSelect = /select/gi.test(type);

  const isDateline = /dateline/gi.test(type);

  const isRadio = /radio/gi.test(type);

  const isCheckBox = /checkbox/gi.test(type);

  // Handlers
  const handleChangeValue = (event = {}, { isReCaptcha = false } = {}) => {
    const { target } = event;

    const hasFormik = !isEmpty(formik);
    const hasExternalCallback = !isEmpty(onChange);

    if (isReCaptcha) {
      /** Here we can do any relevant validation for ReCAPTCHAs */
    }

    if (disable || isReCaptcha) return;

    if (hasFormik) formik.handleChange(event);

    if (isText) {
      if (hasExternalCallback) onChange(formikValue);

      setValue(formikValue);
    }

    if (isCheckBox) {
      const checks = options.map(_option_ =>
        _option_.label === target.value ? { ..._option_, isChecked: target.checked } : _option_,
      );

      setOptions(checks);
    }

    if (isSelect) {
      const option = options.find(_option_ => _option_.value === target.value);

      onChange(option);
      setCurrentOption(option);
    }

    if (isDateline) {
      onChange(target.value);
      setValue(target.value);
    }

    if (isRadio) {
      const newOption = options.find(_option_ => {
        const isThis = new RegExp(target.value, "ig").test(_option_.label);

        return isThis;
      });

      if (!isEmpty(newOption)) {
        onChange(newOption.label);
        setValue(newOption.label);
        setCurrentOption(newOption);
      }
    }
  };

  function onClick(/** event */) {
    if (disable) return;

    setFocus(true);
  }

  function onBlur(event) {
    if (disable) return;

    if (!isEmpty(formik)) {
      formik.handleBlur(event);
    }

    setFocus(false);
  }

  const actions = { handleChangeValue, onClick, onBlur, setOptions };

  const payload = {
    value,
    formikValue,
    formikError,
    isTouched,
    options,
    currentOption,
    focus,
    hasError,
    isSuccess,
    isText,
    isSelect,
    isDateline,
    isRadio,
  };

  const inputModel = { payload, focus, hasError, isSuccess, actions };

  return inputModel;
};
