import styled from "styled-components";

const ContainerGradient = styled.div`
  background-image: radial-gradient(
      circle at 50% 71%,
      var(--bl-color-grayscale-0),
      rgba(255, 255, 255, 0) 25%
    ),
    radial-gradient(circle at 50% 14%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.93) 85%),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 29%,
      rgba(255, 255, 255, 0.11) 53%,
      rgba(255, 255, 255, 0.98) 96%,
      var(--bl-color-grayscale-0) 96%
    ),
    url("${props => props.image}");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default ContainerGradient;
