import { isNumber } from "lodash";

import styled from "styled-components";

export default styled.div`
  position: ${({ noFloat = false }) => (!noFloat ? "absolute" : "")};
  top: ${({ noFloat = false, top = 15 }) => (!noFloat && isNumber(top) ? `${top}px` : undefined)};
  left: ${({ noFloat = false }) => (!noFloat ? "1.25rem" : undefined)};
  transition: all linear 95ms;
  font-size: ${({ noFloat = false, top = 0 }) =>
    !noFloat && isNumber(top) ? `${top}px` : undefined};
`;
