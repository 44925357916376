import styled from "styled-components";
import { getColor } from "lib/@forms/theme";

export default styled.input`
  margin: 0 10px 0 0;

  &:after {
    width: ${({ size = 17 }) => size}px;
    height: ${({ size = 17 }) => size}px;
    border-radius: ${({ size = 17 }) => size}px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${({ disableColor = "#d1d3d1" }) => disableColor};
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }

  &:checked:after {
    width: ${({ size = 17 }) => size}px;
    height: ${({ size = 17 }) => size}px;
    border-radius: ${({ size = 17 }) => size}px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${getColor({ success: true, withBorder: true })};
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
`;
