import React, { useRef } from "react";

import Icon from "lib/@cms/components/primitive/Icon";

export default function Modal({ show, closeModal, children }) {
  const backdropRef = useRef(null);

  function handleBackdropClick(event) {
    if (backdropRef && backdropRef.current === event.target) {
      closeModal();
    }
  }

  if (!show) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className="tw-flex tw-justify-center tw-items-center tw-fixed tw-inset-0 tw-z-50 tw-p-6"
        role="button"
        onClick={handleBackdropClick}
        onKeyPress={handleBackdropClick}
        tabIndex={0}
        ref={backdropRef}
      >
        <div className="tw-mx-auto tw-rounded-lg tw-shadow-lg tw-relative bl-bg-grayscale-0 tw-cursor-default tw-max-w-full tw-relative">
          <button
            type="button"
            className="tw-absolute tw-top-11 tw-right-8 hover:tw-opacity-75"
            onClick={() => closeModal()}
          >
            <Icon icon={Icon.icon.REMOVE} />
          </button>
          {children}
        </div>
      </div>
      <div className="tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 bl-bg-grayscale-100" />
    </React.Fragment>
  );
}
