import React, { memo } from "react";
import { random } from "lodash";
import {
  CustomInput,
  InputBox,
  InputContainer,
  InputLabel,
  InputLabelError,
  InputLabelText,
} from "lib/@forms/components/styled";
import { useInput } from "lib/@forms/hooks";
import { INPUT, INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

function Input({
  formik = undefined /** formik: Formik instance */,
  keyName = "" /** string|number */,
  name = undefined /** string */,
  autoComplete = undefined /** string */,
  className = "",
  isRequired = false,
  disable = false,
  success: $success = undefined /** Boolean */,
  error: $error = undefined /** Boolean */,
  height = 50,
  label = "",
  type = "text",
  value: $value = undefined,
  minLength = undefined /** minLength: Number */,
  maxLength = undefined /** maxLength: Number */,
  onChange = undefined /** (value = "") => null */,
}) {
  const { payload, actions } = useInput({
    type,
    keyName,
    isRequired,
    disable,
    label,
    onChange,
    formik,
    minLength,
    maxLength,
  });

  const { formikValue, formikField = {}, formikError, isTouched } = payload;

  const $id = keyName || random(0, 99999999);

  let value = formik ? formikValue || "" : payload.value || "";

  let success = (!formikError && !!isTouched && !!value) || (!!value && isRequired && !!isTouched);

  let error = (!!formikError && !!isTouched) || (!value && isRequired && !!isTouched);

  if ($value !== undefined) value = $value;
  if ($success !== undefined) success = $success;
  if ($error !== undefined) error = $error;

  return (
    <InputContainer
      htmlFor={name || $id}
      onBlur={actions.onBlur}
      onClick={actions.onClick}
      success={success}
      error={error}
      like={INPUT}
      height={height}
      className={className}
    >
      <InputLabel top={value ? height * 0.08 : height * 0.32}>
        <InputLabelText
          success={success}
          error={error}
          like={INPUT_TEXT}
          className="bl-text-xxs tw-font-medium"
        >
          {`${label}${!isRequired ? " (Optional)" : ""}`}
        </InputLabelText>
      </InputLabel>

      <InputBox hasData={!!value}>
        <CustomInput
          {...formikField}
          autoComplete={autoComplete}
          id={name || $id}
          name={name || $id}
          disabled={disable}
          type={type}
          success={success}
          error={error}
          className="bl-text-sm tw-font-medium bl-text-grayscale-80"
          // placeholder={label}
          value={value}
          onChange={event => {
            if (onChange !== undefined) onChange(event);
            actions.handleChangeValue(event);
          }}
        />
      </InputBox>

      {!!error && (
        <InputLabelError bottom={error ? -20 : 0} length={formikError?.length || 0}>
          <InputLabelText error like={INPUT_TEXT} className="bl-text-xxs tw-font-medium">
            {formikError}
          </InputLabelText>
        </InputLabelError>
      )}
    </InputContainer>
  );
}

export default memo(Input);
