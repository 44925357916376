import React from "react";
import { random } from "lodash";

import {
  InputContainer,
  InputLabel,
  InputLabelError,
  InputLabelText,
  InputSelect,
  InputSelectOption,
} from "lib/@forms/components/styled";
import { useInput } from "lib/@forms/hooks";
import { INPUT, INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

export default function Select({
  formik = undefined /** formik: Formik instance */,
  keyName = "" /** string|number */,
  isRequired = false,
  type = "select",
  disable = false,
  title = "",
  height = 50,
  // id = undefined, // string|number
  data = [],
  value: $value = undefined,
  onChange = undefined /** (value = "") => null */,
}) {
  const { payload, actions } = useInput({
    type,
    formik,
    keyName,
    isRequired,
    data,
    disable,
    label: title,
    onChange,
  });

  const { formikValue, formikError, isTouched } = payload;

  const $id = keyName || random(0, 99999999);

  const value = $value || formik ? formikValue || "" : payload.value || "";

  const success =
    (!formikError && !!isTouched && !!value) || (!!value && isRequired && !!isTouched);

  const error = (!!formikError && !!isTouched) || (!value && isRequired && !!isTouched);

  return (
    <InputContainer
      htmlFor={$id}
      onBlur={actions.onBlur}
      onClick={actions.onClick}
      success={success}
      error={error}
      like={INPUT}
      height={height}
    >
      <InputLabel top>
        <InputLabelText
          success={success}
          error={error}
          like={INPUT_TEXT}
          className="bl-text-xxs tw-font-medium"
        >
          {title}

          {!isRequired && <span>{" (Optional)"}</span>}
        </InputLabelText>
      </InputLabel>

      {!disable && (
        <InputSelect
          hasData
          id={$id}
          name={$id}
          value={payload?.currentOption?.value || ""}
          onChange={event => {
            if (onChange !== undefined) onChange(event);
            actions.handleChangeValue(event);
          }}
          className="bl-text-sm tw-font-medium bl-text-grayscale-80"
        >
          <InputSelectOption value="">Select a option</InputSelectOption>

          {payload.options.map((item, typeIndex) => (
            <InputSelectOption key={typeIndex.toString()} value={item.value}>
              {item.label}
            </InputSelectOption>
          ))}
        </InputSelect>
      )}

      {!!isTouched ||
        (!!error && (
          <InputLabelError bottom={error ? -20 : 0}>
            <InputLabelText error like={INPUT_TEXT} className="bl-text-xxs tw-font-medium">
              {formikError}
            </InputLabelText>
          </InputLabelError>
        ))}
    </InputContainer>
  );
}
