import { useEffect, useState } from "react";
import { format as formatDate } from "date-fns";
import { camelCase, isEmpty, isString, lowerCase, snakeCase } from "lodash";
import { useFormik } from "formik";

import { INPUT_GENERATOR_TYPES } from "lib/@forms/types/pages/forms";

export default function useFormGenerator(options, constructorObj = {}, handles = {}) {
  const inputsKeyNames = inputsKeyNamesBuilder(constructorObj);

  /**   Start Local State   */
  const [inputs, setInputs] = useState([]);
  /**   End Local State   */

  /**   Start Formik   */
  const { onSubmit = () => null, onValidate = () => ({}) } = handles;

  const formikValidationModels = inputsValidatorBuilder(constructorObj);

  const formik = useFormik({
    initialValues: inputsKeyNames,
    onSubmit,
    validate: onValidate,
  });
  /**   End Formik   */

  const actionsInitialData = {};

  const actions = inputs.reduce(actionsBuilder, actionsInitialData);

  /**   Start Handles   */
  function onChangeTextInput(/** { key = "", value = "" } */) {}

  function onChangeSelectInput(/** { key = "", value = {}, data = [] } */) {}

  function onChangeRadioInput(/** { key = "", value = {}, data = [] } */) {}

  function onChangeCheckBoxInput(/** { key = "", data = [] } */) {}

  function onChangeDatelineInput(/** { key = "", value = {} } */) {}

  /**   End Handles   */

  /**   Start Builders   */
  function actionsBuilder(acc, item) {
    const itemActionsInitialData = {};

    const concatItemActions = (acc2, input) => ({
      ...acc2,
      formik,
      [camelCase(input.keyName)]: input.props?.onChange || undefined,
    });

    const itemActions = item.inputs.reduce(concatItemActions, itemActionsInitialData);

    const newActions = { ...acc, ...itemActions };

    return newActions;
  }

  function inputsKeyNamesBuilder(obj = {}) {
    const inputsUpdated = Object.entries(obj).map(inputsUpdatedFormatter);

    const keyNames = inputsUpdated.reduce((acc, item) => {
      item.inputs.forEach(subItem => {
        acc[subItem.keyName] = "";
      });

      return acc;
    }, {});

    return keyNames;
  }

  function inputsValidatorBuilder(obj = {}) {
    const inputsUpdated = Object.entries(obj).map(inputsUpdatedFormatter);

    /** validated models */
    const validationModels = inputsUpdated.reduce((boxOfValidatedModels, model) => {
      model.inputs.forEach(modelData => {
        boxOfValidatedModels[modelData.keyName] = {};
        if (modelData.name) {
          boxOfValidatedModels[modelData.keyName].name = modelData.keyName;
        }

        if (modelData.type) {
          boxOfValidatedModels[modelData.keyName].type = lowerCase(modelData.type);
        }

        boxOfValidatedModels[modelData.keyName].isRequired = !!modelData.props?.isRequired || false;

        if (modelData.props?.regex) {
          boxOfValidatedModels[modelData.keyName].regex = modelData.props.regex;
        }

        if (modelData.props?.label) {
          boxOfValidatedModels[modelData.keyName].label = modelData.props.label;
        }

        if (modelData.props?.maxLength) {
          boxOfValidatedModels[modelData.keyName].maxLength = modelData.props.maxLength;
        }

        if (modelData.props?.minLength) {
          boxOfValidatedModels[modelData.keyName].minLength = modelData.props.minLength;
        }

        if (modelData.props?.helperText) {
          boxOfValidatedModels[modelData.keyName].helperText = modelData.props.helperText;
        }

        if (modelData.props?.title) {
          boxOfValidatedModels[modelData.keyName].title = modelData.props.title;
        }
      });

      return boxOfValidatedModels;
    }, {});

    return validationModels;
  }

  function inputsUpdatedFormatter(
    //  param 1: Object as Array
    [
      key, //  Object Key
      $values, //  Object Value/Data
    ],
    id, //  param 2: Index
  ) {
    const hasLinks = !isEmpty($values.links);

    const item = {
      ...$values,
      key,
      id,
      inputs: $values.inputs.map(input =>
        inputsItemFormatter({ title: $values.title || $values.description, input }),
      ),
    };

    if (hasLinks) {
      const linksUpdated = $values.links.map(
        ({ relationName = "", acceptedValue = "", form = {} }) => {
          const formUpdated = Object.entries(form).map(inputsUpdatedFormatter);

          return {
            relationName,
            acceptedValue,
            form: formUpdated,
          };
        },
      );

      item.links = linksUpdated;
    }

    return item;
  }

  function inputsItemFormatter({ title = "", input = {} }) {
    const inputType = snakeCase(input.type).toUpperCase();

    const titleAndNameAreEqual = camelCase(title) !== camelCase(input.name);

    const keyName = `${title ? `${camelCase(titleAndNameAreEqual ? title : "")}_` : ""}${
      camelCase(input.name) || ""
    }`;

    let inputFormatted = {};

    if (
      inputType === INPUT_GENERATOR_TYPES.TEXT ||
      inputType === INPUT_GENERATOR_TYPES.TEXTAREA ||
      inputType === INPUT_GENERATOR_TYPES.NUMBER ||
      inputType === INPUT_GENERATOR_TYPES.EMAIL
    ) {
      inputFormatted = {
        type: inputType,
        props: {
          value: undefined,
          label: input.label,
          isRequired: input.required,
          type: input.type,
          minLength: Number(input.minLength || 0),
          maxLength: Number(input.maxLength || 0),
          onChange: value => onChangeTextInput({ key: keyName, value }),
        },
      };

      if (options && options[keyName]) inputFormatted.props.value = options[keyName];
    } else if (inputType === INPUT_GENERATOR_TYPES.CHECKBOX) {
      const checkboxData = isString(input.data) ? options[input.data] : input.data;

      inputFormatted = {
        type: INPUT_GENERATOR_TYPES.CHECKBOX,
        props: {
          data: checkboxData,
          isRequired: input.required,
          onChange: $data => onChangeCheckBoxInput({ key: keyName, data: $data }),
        },
      };
    } else if (inputType === INPUT_GENERATOR_TYPES.SELECT) {
      inputFormatted = {
        type: INPUT_GENERATOR_TYPES.SELECT,
        props: {
          title: input.label,
          helperText: input.label,
          data: [],
          isRequired: input.required,
          onChange: value =>
            onChangeSelectInput({
              key: keyName,
              value,
              data: options[input.data],
            }),
        },
      };

      if (options && input && input.data && options[input.data]) {
        inputFormatted.props.data = options[input.data];
      }
    } else if (inputType === INPUT_GENERATOR_TYPES.RADIO) {
      inputFormatted = {
        type: INPUT_GENERATOR_TYPES.RADIO,
        props: {
          data: options[input.data],
          isRequired: input.required,
          onChange: value =>
            onChangeRadioInput({
              key: keyName,
              value,
              data: options[input.data],
            }),
        },
      };

      if (options && input && input.data && options[input.data]) {
        inputFormatted.props.data = options[input.data];
      }
    } else if (inputType === INPUT_GENERATOR_TYPES.DATELINE) {
      inputFormatted = {
        type: INPUT_GENERATOR_TYPES.DATELINE,
        props: {
          label: input.label,
          initialDate: options[input.data]
            ? options[input.data][0]
            : formatDate(new Date(), "yyyy-MM-dd"),
          onChange: value => onChangeDatelineInput({ key: keyName, value }),
        },
      };
    } else if (inputType === INPUT_GENERATOR_TYPES.RE_CAPTCHA) {
      inputFormatted = {
        type: INPUT_GENERATOR_TYPES.RE_CAPTCHA,
        props: {
          isRequired: input.required,
          label: input.label,
          initialDate: false,
          onChange: value => onChangeDatelineInput({ key: keyName, value }),
        },
      };
    }

    inputFormatted = {
      ...inputFormatted,
      id: keyName,
      keyName,
      props: {
        ...inputFormatted.props,
        keyName,
        styles: input.theme || undefined,
        regex: input.regex || undefined,
      },
    };

    return inputFormatted;
  }

  function inputsBuilder() {
    const inputsUpdated = Object.entries(constructorObj).map(inputsUpdatedFormatter);

    setInputs(inputsUpdated);

    return inputsUpdated;
  }

  /**   End Builders   */

  useEffect(() => {
    inputsBuilder();
  }, [options]);

  return {
    formikKeyNames: inputsKeyNames,
    formikValidationModels,
    formik,
    data: options,
    inputs,
    actions,
  };
}
