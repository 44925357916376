import React, { memo, createRef, forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useInput } from "lib/@forms/hooks";
import { InvisibleBox } from "lib/@forms/components/styled";
import { CheckBox } from "lib/@forms/components/primitive";

function ReCaptcha(
  {
    formik = undefined /** formik: Formik instance */,
    keyName = "" /** string|number */,
    isRequired = false,
    disable = false,
    siteKey = process.env.GATSBY_RE_CAPTCHA_CLIENT_API_KEY,
    label = "",
    type = "text",
    reCaptchaType = "image" /** optional image or audio The type of initial captcha (defaults: image) */,
    onChange = undefined /** (value = "") => null */,
    onExpired = undefined /** Optional callback when the challenge is expired and has to be redone by user. By default it will call the onChange with null to signify expired callback. */,
    onErrored = undefined /** Optional callback when the challenge errored, most likely due to network issues. */,
    stoken = undefined /** Optional set the stoken parameter, which allows the captcha to be used from different domains, see reCAPTCHA secure-token (https://github.com/slushie/recaptcha-secure-token) */,
    hl = undefined /** Optional set the hl parameter, which allows the captcha to be used from different languages, see (https://developers.google.com/recaptcha/docs/language) */,
    badge = undefined /** Optional bottomright, bottomleft or inline. Positions reCAPTCHA badge. Only for invisible reCAPTCHA */,
    /** Optional: The component instance also has some utility functions that can be called. These can be accessed via ref.
     * getValue(): returns the value of the captcha field
     * getWidgetId(): returns the recaptcha widget Id
     * reset(): forces reset. See the JavaScript API doc
     * execute(): programmatically invoke the challenge
     *    - need to call when using "invisible" reCAPTCHA - example below
     * executeAsync(): programmatically invoke the challenge and return a promise that resolves to the token or errors(if encountered).
     *    - alternative approach to execute() in combination with the onChange() prop - example below
     */
    size = "normal" ||
      "compact" ||
      "invisible" /** Optional compact, normal or invisible. This allows you to change the size or do an invisible captcha) */,
    theme = "light" ||
      "dark" /** optional light or dark The theme of the widget (defaults: light). */,
    tabIndex = 0 /** optional The tabindex on the element (default: 0) */,
  },
  ref,
) {
  const checkboxRef = createRef();

  const data = [{ id: 0, isChecked: false, label }];

  const { actions } = useInput({
    type: "checkbox",
    formik,
    keyName,
    isRequired,
    data,
    disable,
    label,
    onChange,
  });

  async function $onExpired(value) {
    if (onExpired !== undefined) onExpired(value);

    await $setValue(false);
  }

  async function $onErrored(error) {
    if (onErrored !== undefined) onErrored(error);

    await $setValue(false);
  }

  async function $onChange(/** value */) {
    await $setValue(true);
  }

  async function $setValue(isChecked = false) {
    if (checkboxRef.current) {
      checkboxRef.current.checked = isChecked;

      if (formik) {
        formik.setFieldValue(keyName, isChecked);
      }
    }
  }

  return (
    <div className="bl-text-xxs tw-font-medium">
      <InvisibleBox>
        <CheckBox
          ref={checkboxRef}
          formik={formik}
          keyName={keyName}
          isRequired={isRequired}
          disable={disable}
          type={type}
          title={label}
          data={data}
          onChange={event => {
            if (onChange !== undefined) onChange(event);
            actions.handleChangeValue(event, { isReCaptcha: true });
          }}
        />
      </InvisibleBox>

      <div className="tw-relative tw-z-10">
        <ReCAPTCHA
          ref={ref}
          tabindex={tabIndex}
          badge={badge}
          stoken={stoken}
          hl={hl}
          size={size}
          theme={theme}
          sitekey={siteKey}
          type={reCaptchaType}
          onExpired={$onExpired}
          onErrored={$onErrored}
          onChange={$onChange}
        />
      </div>
    </div>
  );
}

export default memo(forwardRef(ReCaptcha));
