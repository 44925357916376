import React from "react";
import classnames from "classnames";

import Button from "lib/@cms/components/primitive/Button";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

const VARIANTS = {
  DECORATION_NONE: "DECORATION_NONE",
  DECORATION_DOTTEN: "DECORATION_DOTTEN",
};

function BodyText({ variant = VARIANTS.DECORATION_NONE, data }) {
  return (
    <div className="tw-w-full">
      {data.title && <Title>{data.title}</Title>}

      {data.list && <List list={data.list} variant={variant} />}

      {data.linksList && <Links links={data.linksList} />}

      {data.buttonName && <ButtonSection buttonName={data.buttonName} buttonUrl={data.buttonUrl} />}
    </div>
  );
}

BodyText.variant = VARIANTS;

export default withErrorBoundaryHOC(BodyText);

// --- Components ---

function Title({ children }) {
  return (
    <h4 className="tw-font-semibold bl-text-xl sm:bl-text-xl lg:bl-text-xl tw-leading-tight tw-mb-6">
      {children}
    </h4>
  );
}

function List({ list, variant }) {
  const listStyles = getListStyles(variant);

  return (
    <ul className="bl-text-md">
      {list.map((item, index) => {
        const text = item.item || item.text;

        return (
          <li key={`List-item-${index}`} className={classnames("tw-py-2", listStyles)}>
            {text}
          </li>
        );
      })}
    </ul>
  );
}

function getListStyles(variant) {
  if (variant === VARIANTS.DECORATION_DOTTEN) {
    return "tw-ml-6 tw-list-disc";
  }

  return "";
}

function Links({ links }) {
  return (
    <ul className="bl-text-md tw-mt-4">
      {links.map((item, index) => {
        return (
          <li key={`Links-item-${index}`} className="tw-py-2">
            <a href={item.url} className="bl-text-primary">
              {item.item}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

function ButtonSection({ buttonName, buttonUrl }) {
  return (
    <div className="tw-pt-8">
      <Button is="a" href={buttonUrl} fill={Button.fill.FILLED}>
        {buttonName}
      </Button>
    </div>
  );
}
