import React from "react";
import { isEmpty } from "lodash";
import FormLinked from "./FormLinked";
import InputGenerator from "./InputGenerator";

export default function Form({
  formik /** formik: Formik Instance */,
  title = "",
  description = "",
  inputs: components = [],
  links = [],
  // key = "",
  cols = 1,
  noGap = false,
  noMargin = false,
}) {
  return (
    <div
      className={`tw-col-span-2 tw-grid tw-grid-cols-1 tw-gap-${noGap ? 0 : 4} tw-my-${
        noMargin ? 0 : 4
      } tw-pb-12`}
    >
      <h4 className="bl-text-md tw-mb-0 tw-font-semibold">{title}</h4>

      <p className="bl-text-xs tw-mb-0 tw-font-thin">{description}</p>

      <div className={`tw-p-0 tw-col-span-1 tw-grid tw-grid-cols-${cols} tw-gap-x-4 tw-gap-y-7`}>
        {components.map(componentProps => {
          let fullWidthStyle = "";
          const maxColSpan = cols - 1 <= 1 ? cols : cols - 1;

          if (
            !isEmpty(componentProps) &&
            !isEmpty(componentProps.props) &&
            !isEmpty(componentProps.props.styles) &&
            !!componentProps.props.styles.fullWidth
          ) {
            fullWidthStyle = `tw-col-span-${maxColSpan}`;
          }

          return (
            <div
              className={`tw-grid tw-grid-cols-1 ${fullWidthStyle}`}
              key={componentProps.keyName}
            >
              <InputGenerator {...componentProps} formik={formik} />
            </div>
          );
        })}
      </div>

      {!isEmpty(links) &&
        links.map((link, linkIndex) => (
          <FormLinked
            inputs={components}
            key={`${link.relationName}-${linkIndex + 1}`}
            formik={formik}
            cols={cols}
            {...link}
          />
        ))}
    </div>
  );
}
