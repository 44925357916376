import React from "react";
import { random } from "lodash";

import {
  GroupInputRadio,
  InputContainer,
  InputLabel,
  InputLabelText,
  RadioButton,
} from "lib/@forms/components/styled";
import { useInput } from "lib/@forms/hooks";
import { INPUT_TEXT } from "lib/@forms/types/theme/getColorType";

export default function Radio({
  formik = undefined /** formik: Formik instance */,
  keyName = "" /** string|number */,
  isRequired = false,
  disable = false,
  type = "radio",
  // id = "",
  title = "",
  data = [],
  height = 55,
  direction = "vertical" || "horizontal",
  onChange = undefined /** (value = "") => null */,
}) {
  const { payload, actions } = useInput({
    type,
    formik,
    keyName,
    isRequired,
    data,
    disable,
    label: title,
    onChange,
  });

  const { formikValue, formikError, isTouched } = payload;

  const $id = keyName || random(0, 99999999);

  const value = formik ? formikValue || "" : payload.value || "";

  const success = (!formikError && !!isTouched) || (!!value && isRequired && !!isTouched);

  const error =
    ((!!formikError || !success) && !!isTouched) || (!value && isRequired && !!isTouched);

  let $direction = "row";

  if (/vertical/gi.test(direction)) {
    $direction = "column";
  }

  return (
    <InputContainer hiddenBg noPadding height={height}>
      <InputLabel noFloat>
        <InputLabelText
          success={success}
          error={error}
          like={INPUT_TEXT}
          className="bl-text-md tw-font-medium"
        >
          {title}
        </InputLabelText>
      </InputLabel>

      <GroupInputRadio role="group" htmlFor={$id} direction={$direction}>
        {payload.options.map(item => {
          const itemName = `${$id}-${item.value}`;
          const isChecked = payload.currentOption.value === item.value;

          return (
            <div
              key={itemName}
              role="presentation"
              className="tw-mr-6 tw-flex tw-flex-row tw-justify-start tw-items-center"
            >
              <RadioButton
                name={$id}
                id={itemName}
                type={type}
                checked={isChecked}
                value={item.label}
                onBlur={actions.onBlur}
                onClick={actions.onClick}
                onChange={event => {
                  if (onChange !== undefined) onChange(event);
                  actions.handleChangeValue(event);
                }}
              />

              <span className="bl-text-xs tw-font-medium">{item.label}</span>
            </div>
          );
        })}
      </GroupInputRadio>
    </InputContainer>
  );
}
